<template>
<!-- 工单管控催办 -->
  <div style="padding:16px" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card>
      <el-form
        ref="queryForm"
        :model="queryParams"
        size="small"
        label-width="90px"
        @submit.native.prevent
      >
        <div class="formModel">
          <el-form-item label="工单号" prop="TicketId">
            <el-input
              class="formItem"
              v-model="queryParams.TicketId"
              placeholder="请输入工单号"
              clearable
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="标题" prop="Title">
            <el-input
              class="formItem"
              v-model="queryParams.Title"
              placeholder="请输入标题"
              clearable
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="业务类型" prop="replaceType">
            <el-select
              v-model="queryParams.ticket_type"
              placeholder="请选择业务类型"
              clearable
              @keyup.enter.native="handleQuery"
            >
              <el-option
                v-for="item in ticket_types"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="催办时间" prop="createdTimeRange">
            <el-date-picker
              v-model="createdTimeRange"
              type="daterange"
              class="formItem"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              clearable
              @keyup.enter.native="handleQuery"
            >
            </el-date-picker>
          </el-form-item>
          <div class="formItem btns">
            <el-button type="primary" size="small" icon="el-icon-search" @click="handleQuery"
              >搜索
            </el-button>
            <el-button icon="el-icon-refresh" size="small" @click="handleFormRest('queryForm')"
              >重置
            </el-button>
          </div>
        </div>
      </el-form>
    </el-card>
    <el-card style="margin-top: 15px; padding-bottom: 20px">
      <el-table v-loading="loading" :data="list" style="width: 100%; margin-top: 20px">
        <el-table-column property="TicketId" width="160" label="工单号">
          <template slot-scope="scope">
            <span class="ticketId" @click="getDetail(scope.row.TicketId,scope.row.TicketType)">{{ scope.row.TicketId }}</span>
          </template>
        </el-table-column>
        <el-table-column property="TicketType" label="催办类型">    
          <template v-slot="scope">
              {{ getTypeName(scope.row.TicketType) }}
          </template>
        </el-table-column>
        <el-table-column property="TicketName" label="标题"></el-table-column>
        <el-table-column property="State" label="节点"></el-table-column>
        <el-table-column property="staffs" label="处理人"></el-table-column>
        <el-table-column property="CreateTime" label="催办时间"></el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageParams.page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[25, 50, 100]"
        :page-size.sync="pageParams.limit"
        :total="total"
        style="margin-top: 20px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import { saveAs } from 'file-saver';
import { getRemindLogs } from '@/api/monitor';
import {removeEmptyStringFields} from '@/utils/common'
export default {
  name: 'ticket_remind',
  data() {
    return {
      fullscreenLoading: false,
      loading: false,
      total: 0,
      list: [],
      queryParams : {
        TicketId: '', //工单号
        Title: '', //工单名称
        ticket_type:'',
        startCreateTime: '', //结单开始时间
        endCreateTime: '', //结单结束时间
      },
      pageParams: {
        page: 1,
        limit: 25,
      },
      ticket_types:[
        {'id':1,value:'1',label:'退役'},
        {'id':2,value:'2',label:'搬迁'},
        {'id':3,value:'3',label:'网络异常'},
        {'id':4,value:'4',label:'TPC异常'},
      ],
      createdTimeRange:[],
    };
  },
  created() {
    if (sessionStorage.getItem('ticket_remind_params')) {
      this.queryParams = JSON.parse(sessionStorage.getItem('ticket_remind_params'));
      if(this.queryParams.startCreateTime){
        this.createdTimeRange = [this.queryParams.startCreateTime,this.queryParams.endCreateTime]
      }
    }
    this.getList();
  },
  methods: {
    //查看详情
    getDetail(ticket_id,type) {
      if (type=='1'){
        this.$router.push({
          path: '/appManage/monitor/serverDecommissioning/detail',
          query: {
            ticketId: ticket_id,
          },
        });
      }
      if (type=='2'){
        this.$router.push({
          path: '/appManage/monitor/serverRelocation/detail',
          query: {
            ticketId: ticket_id,
          },
        });
      }
      if (type=='3'){
        this.$router.push({
          path: '/appManage/monitor/networkMalfunction/detail',
          query: {
            ticketId: ticket_id,
          },
        });
      }
      if (type=='4'){
        this.$router.push({
          path: '/appManage/monitor/TPC_Abnormal/detail',
          query: {
            ticket_id: ticket_id,
          },
        });
      }
    },
    //列表
    getList() {
      this.loading = true;
      getRemindLogs({ ...this.pageParams,...this.formattingParams()})
        .then((res) => {
          console.log("res!!!");
            this.list = res.data.data;
            this.total = res.data.count;
            this.loading = false;
          }
        )
        .catch(() => {
          this.loading = false;
        });
    },
    getTypeName(type){
      if(type=='1') return '退役'
      if(type=='2') return '搬迁'
      if(type=='3') return '网络异常'
      if(type=='4') return 'TPC异常'
    },
    formattingParams(){
      let params = JSON.stringify(this.queryParams);
      let paramsNew = JSON.parse(params);
      if (this.createdTimeRange?.length) {
        paramsNew.startCreateTime = `${this.createdTimeRange[0]} 00:00:00`; //创建开始时间
        paramsNew.endCreateTime = `${this.createdTimeRange[1]} 23:59:59`; //创建截止时间
      }
      return removeEmptyStringFields(paramsNew)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.pageParams.page = 1;
      this.pageParams.limit = 25;
      this.getList();
    },
    // 重置
    handleFormRest(formName) {
      sessionStorage.removeItem('ticket_remind_params');
      this.queryParams={
        TicketId: '', //工单号
        Title: '', //工单名称
        ticket_type:'',
        startCreateTime: '', //结单开始时间
        endCreateTime: '', //结单结束时间
      }
      this.createdTimeRange = [];
      this.handleQuery();
    },
    // 监听每页条数改变
    handleSizeChange(newSize) {
      this.pageParams.limit = newSize;
      this.pageParams.page = 1;
      this.getList();
    },
    // 监听当前页面变化
    handleCurrentChange(newPage) {
      this.pageParams.page = newPage;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .demo-ruleForm .el-form-item {
  margin-bottom: 16px !important;
}
.item-shortcut-statement {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  span {
    padding-right: 20px;
    font-size: 14px;
    line-height: 18px;
    color: #333;
  }
}
.ticketId {
  color: #1890ff;
  cursor: pointer;
}
.standard-blue-link {
  color: #1890ff;
  text-decoration: none;
}
.formModel {
  display: flex;
  flex-flow: row wrap;
}
.formItem {
  // width: 175px !important;
  margin-bottom: 20px;
}
::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-card__body {
  padding: 15px 20px 0 20px !important;
}
.btns {
  margin-left: 20px;
}
</style>
